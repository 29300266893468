import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { getClosestFocusable } from './algorithm/closestElement';
import { focusElementInGroupIfNeeded } from './focusGroupElement';
import type {
  El,
  ElWithRect,
  GetDirection,
  OnGroupFocus,
  Routes
} from './types';
import { keyListeners, preventScroll } from './useKeyListeners';
import { useMouse } from './useMouse';
import { addDebuggingStyles, allFocusableChildren, getData } from './utils';
import { withRect } from './withRect';

const warn = () =>
  console.warn(
    'Missing FocusProvider. Have you wrapped your app with <FocusProvider>?'
  );

const FocusContext = createContext<{
  debug: boolean;
  updateElements: () => void;
  registerRoot: (div: HTMLDivElement) => void;
  updateChildrenInGroup: (div: ChildNode) => void;
  onGroupMount: (div: ChildNode) => void;
  onGroupUnmount: (id: string) => void;
  onGroupFocus: OnGroupFocus;
  updateFocus: () => void;
  averageInputCharacterWidth: number | undefined;
}>({
  debug: false,
  onGroupFocus: warn,
  onGroupMount: warn,
  onGroupUnmount: warn,
  registerRoot: warn,
  updateChildrenInGroup: warn,
  updateElements: warn,
  updateFocus: warn,
  averageInputCharacterWidth: undefined
});

const ROUTE_EXPIRATION_MINUTES = 10;

export const FocusProvider = ({
  children,
  getDirection,
  getRouteKey,
  averageInputCharacterWidth = 8
}: {
  children: ReactNode;
  getDirection: GetDirection;
  /** Route key should be unique for the specific instance of a route visit.
   *
   * When navigating back from a page, the previous pages should have the same route
   * key as when they were first visited. The same URL should not always have the
   * same route key since the same url might exist in history twice.
   *
   * For example: When using react-router, useLocation().key can be used as route key.
   */
  getRouteKey: () => string;
  /**
   * Not important to get 100% accurate.
   * (Possible improvement of focus engine is to calculate this value automatically)
   * */
  averageInputCharacterWidth?: number;
}) => {
  const focusedElement = useRef<ElWithRect>();
  const routes = useRef<Routes>({});
  const [debug, setDebug] = useState(false);
  const root = useRef<HTMLDivElement>();
  const { isCursorVisible, isKeyboardVisible } = useMouse();
  const previousRoute = useRef<string>();
  const activeRoute = useRef<string>();

  const updateElements = useCallback(() => {
    if (!root.current) throw Error('updating elements before root is set');

    const focusableChildren = allFocusableChildren({
      node: root.current,
      groups: [],
      layer: undefined,
      routeKey: getRouteKey(),
      isFocusGroup: false
    });

    const saveFocusedElement = (el: El) => {
      const routeKey = getRouteKey();
      const { parentGroupId } = el;
      const previousFocusedElement = focusedElement.current;
      focusedElement.current = withRect(el);

      // save previous element focus
      if (
        el.parentGroupId &&
        previousFocusedElement &&
        previousFocusedElement.element.routeKey === el.routeKey &&
        previousFocusedElement.element.parentGroupId !== parentGroupId &&
        routes.current[routeKey].focusGroups[el.parentGroupId]
      ) {
        routes.current[routeKey].focusGroups[
          el.parentGroupId
        ].previousFocusOnSameRoute = previousFocusedElement;
      }

      if (!focusedElement.current.element.isFocusGroup) {
        const { parentGroupId } = el;
        if (
          !parentGroupId ||
          !routes.current[routeKey]?.focusGroups[parentGroupId]
        )
          return;

        // add/update route
        routes.current[routeKey].lastFocusedGroup = parentGroupId;
        const indexInGroup = routes.current[routeKey].focusGroups[
          parentGroupId
        ].focusableChildren.findIndex((e) => e.node === el.node);
        routes.current[routeKey].lastFocusedIndex = indexInGroup;
      }
    };

    /**
     * This function needs to be called because some browsers doesn't
     * auto scroll partly visible elements into the viewport when focused.
     * */
    const scrollFocusedElementIntoView = (el: El) => {
      const focused = document.querySelector(':focus');
      if (!focused) return;
      if (!el.parentGroupId) return;
      if (el.isFocusGroup) return;
      const focusGroup =
        routes.current[getRouteKey()]?.focusGroups?.[el.parentGroupId];
      if (!focusGroup) return;

      focused?.scrollIntoView({
        behavior: 'smooth',
        inline: focusGroup.horizontalAutoScrollPosition || 'nearest',
        block: focusGroup.verticalAutoScrollPosition || 'nearest'
      });
    };

    const onMouseEnter = (e: Event) => {
      if (!isCursorVisible.current) return;
      if (isKeyboardVisible.current) return;
      return (e.target as any).focus({ preventScroll });
    };

    focusableChildren.forEach((f) => {
      // remember current focused element
      f.node.addEventListener('focus', () => {
        saveFocusedElement(f);
        scrollFocusedElementIntoView(f);
      });

      // trigger focus on mouse enter
      if (!f.isFocusGroup) {
        f.node.addEventListener('mouseenter', onMouseEnter);
      }

      // has initial focus
      if (document.activeElement === f.node) {
        saveFocusedElement(f);
      }
    });
    const focusableElements = focusableChildren.map(withRect);
    keyListeners.update({
      averageInputCharacterWidth,
      focusableElements,
      focusedElement,
      focusGroups: routes.current[getRouteKey()]?.focusGroups ?? {},
      getDirection,
      onDirectionKey: (info) => {
        if (debug) {
          addDebuggingStyles({
            previousFocusedElement: info.previousFocusedElement,
            focusedElement: focusedElement.current,
            focusableElements,
            candidates: info.candidates,
            closestElement: info.closestElement
          });
        }
      }
    });
  }, [
    getRouteKey,
    averageInputCharacterWidth,
    getDirection,
    isCursorVisible,
    isKeyboardVisible,
    debug
  ]);

  const updateChildrenInGroup = useCallback(
    (group: ChildNode) => {
      const { groupId, isFocusGroup, layer } = getData(group);
      const focusableChildren = allFocusableChildren({
        layer,
        node: group,
        groups: [groupId],
        isFocusGroup,
        routeKey: getRouteKey()
      });

      if (!routes.current[getRouteKey()]?.focusGroups[groupId]) return;

      routes.current[getRouteKey()].focusGroups[
        groupId
      ].focusableChildren = focusableChildren;
    },
    [getRouteKey]
  );

  const currentFocusedElementExistsInDocument = useCallback(() => {
    return (
      focusedElement.current?.element.node &&
      document.body.contains(focusedElement.current?.element.node)
    );
  }, []);

  /** Updates focus to a new element if previously focused element has been removed */
  const updateFocus = useCallback(() => {
    // Stop if another page
    if (!currentFocusedElementExistsInDocument()) {
      const onAnotherPage =
        focusedElement.current?.element.routeKey !== getRouteKey();
      if (onAnotherPage) return;
      if (!root.current) return;
      const focusableElements = allFocusableChildren({
        node: root.current,
        groups: [],
        layer: undefined,
        routeKey: 'root',
        isFocusGroup: false
      });
      const { closestElement } = getClosestFocusable({
        averageInputCharacterWidth,
        direction: 'any',
        fromElement: focusedElement,
        elements: focusableElements.map(withRect),
        focusGroups: routes.current[getRouteKey()]?.focusGroups
      });
      if (closestElement?.element.node) {
        (closestElement.element.node as any).focus({ preventScroll });
      }
    }
  }, [
    averageInputCharacterWidth,
    currentFocusedElementExistsInDocument,
    getRouteKey
  ]);

  const registerRoot = useCallback(
    (div: HTMLDivElement) => {
      root.current = div;
      updateElements();
    },
    [updateElements]
  );

  const onRouteSwitch = useCallback(() => {
    const routeKey = getRouteKey();
    activeRoute.current = routeKey;

    // update blur on existing routes when switching route
    if (previousRoute.current && routes.current[previousRoute.current]) {
      const { lastFocusedGroup, lastFocusedIndex } = routes.current[
        previousRoute.current
      ];
      if (lastFocusedGroup && lastFocusedIndex != null) {
        routes.current[previousRoute.current].lastFocusBeforeLeaveRoute = {
          group: lastFocusedGroup,
          index: lastFocusedIndex
        };
        routes.current[
          previousRoute.current
        ].hasReceivedFocusFromHistory = false;
      }
    }

    previousRoute.current = activeRoute.current;
  }, [getRouteKey]);

  // Handling edge case that happens when combining routes that uses the focus engine
  // with routes that doesn't. This make sure a focus is re-triggered when backing from a
  // non-focus-engine route to a focus-engine route. Otherwise the engine doesn't know that
  // the route has been switched.
  const handleRouteSwitchEdgeCase = useCallback(() => {
    const routeKey = getRouteKey();
    if (Object.keys(routes.current[routeKey].focusGroups).length === 0) {
      onRouteSwitch();
    }
  }, [getRouteKey, onRouteSwitch]);

  const onGroupUnmount = useCallback(
    (id: string) => {
      const routeKey = getRouteKey();
      if (!routes.current[routeKey]) return;
      const toRemove = routes.current[routeKey]?.focusGroups[id];
      delete routes.current[routeKey].focusGroups[id];
      if (toRemove?.previousFocusOnSameRoute) {
        (toRemove.previousFocusOnSameRoute.element.node as any)?.focus();
      }
      handleRouteSwitchEdgeCase();
    },
    [getRouteKey, handleRouteSwitchEdgeCase]
  );

  const onGroupFocus: OnGroupFocus = useCallback(
    (e: React.FocusEvent<HTMLDivElement, Element>, id: string) => {
      // TODO: Explain why is this needed
      if (document.activeElement !== e.currentTarget) return;

      // If a focus group has been focused, find child element within
      const focusGroup = routes.current[getRouteKey()]?.focusGroups[id];
      if (focusGroup) {
        const { focusableChildren } = focusGroup;

        const nodeToFocusWhenEnterFocusGroup = focusableChildren.find(
          (c) => !c.isFocusGroup
        );

        if (nodeToFocusWhenEnterFocusGroup?.node) {
          (nodeToFocusWhenEnterFocusGroup?.node as any)?.focus({
            preventScroll
          });
        }
      }
    },
    [getRouteKey]
  );

  const removeExpiredRoutes = () => {
    const now = new Date();
    Object.entries(routes.current).forEach(([key, route]) => {
      const updatedAt = new Date(route.updatedAt);
      const diff = now.getTime() - updatedAt.getTime();
      const minutes = diff / 1000 / 60;
      if (minutes > ROUTE_EXPIRATION_MINUTES) {
        delete routes.current[key];
      }
    });
  };

  const onGroupMount = useCallback(
    (el: ChildNode) => {
      const {
        groupId: id,
        isFocusGroup: _isFocusGroup,
        preferFocus,
        ...dataProps
      } = getData(el);
      const routeKey = getRouteKey();
      removeExpiredRoutes();

      if (!routes.current[routeKey]) {
        // Add new route data
        routes.current[routeKey] = {
          focusGroups: {},
          preferredFocusGroup: undefined,
          lastFocusedGroup: undefined,
          lastFocusedIndex: undefined,
          hasReceivedFocusFromHistory: false,
          lastFocusBeforeLeaveRoute: undefined,
          updatedAt: new Date().toISOString()
        };
      }
      if (activeRoute.current !== routeKey) onRouteSwitch();

      // Update existing route data
      routes.current[routeKey].updatedAt = new Date().toISOString();
      routes.current[routeKey].focusGroups[id] = {
        ...dataProps,
        focusableChildren: [],
        id,
        previousFocusOnSameRoute:
          routes.current[routeKey].focusGroups[id]?.previousFocusOnSameRoute,
        preferFocus,
        node: el
      };

      if (preferFocus) {
        const currentPreferredFocusGroup =
          routes.current[routeKey].preferredFocusGroup;
        if (currentPreferredFocusGroup) {
          console.warn(
            `Multiple focus groups with preferFocus set to true. Only the first one will be focused.`
          );
        } else {
          routes.current[routeKey].preferredFocusGroup = id;
        }
      }

      updateElements();
      updateChildrenInGroup(el);
      focusElementInGroupIfNeeded({
        routes: routes.current,
        id,
        routeKey
      });

      return () => onGroupUnmount(id);
    },
    [
      getRouteKey,
      onGroupUnmount,
      onRouteSwitch,
      updateChildrenInGroup,
      updateElements
    ]
  );

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') return;
    const onKey = (e: KeyboardEvent) => {
      if (e.key === 'c') {
        setDebug((d) => !d);
      }
    };
    document.addEventListener('keydown', onKey);
    return () => {
      document.removeEventListener('keydown', onKey);
    };
  }, [setDebug]);

  return (
    <FocusContext.Provider
      value={{
        debug,
        updateElements,
        registerRoot,
        updateChildrenInGroup,
        onGroupMount,
        onGroupUnmount,
        onGroupFocus,
        updateFocus,
        averageInputCharacterWidth
      }}
    >
      <RootDiv>{children}</RootDiv>
    </FocusContext.Provider>
  );
};
const RootDiv = ({ children }: { children: ReactNode }) => {
  const { registerRoot } = useFocusEngine();

  return (
    <div
      ref={(ref) => {
        if (!ref) return;
        registerRoot(ref);
      }}
    >
      {children}
    </div>
  );
};

export const useFocusEngine = () => useContext(FocusContext);
