import {
  DeviceInterface,
  DeviceType,
  exists,
  Locale
} from '@sfstudios/shapeshifter';
import { parseUrl } from 'query-string';
import { useEffect, useState } from 'react';
import { DeviceInfo } from '../contract/CommunicationContract';
import { getEnvVariable } from './getEnvVariable';
import { Platform } from './platform';
import {
  extractSamsungTVModelGroup,
  SamsungTVModelGroups
} from './samsungModelData';
import { getSamsungVersion } from './tizenUtils';
import { getExactWebosVersion } from './webosUtils';
import { getPhilipsVersion } from './philipsUtils';

export type ParsedAppVersion = [
  // major
  number,

  // minor
  number,

  // patch
  number
];

/**
 * Parse and use deviceInfo from the URL if possible.
 * We pass along the device information from Samsung through
 * the URL in order to have that data available as soon as possible.
 */
const parsed = parseUrl(window?.location.href);
let parsedDeviceInfo = (parsed.query.deviceInfo &&
!Array.isArray(parsed.query.deviceInfo)
  ? JSON.parse(parsed.query.deviceInfo)
  : null) as DeviceInfo | null;

const DEVICE_INFO_STORAGE_KEY = '@sfanytime/deviceInfo';

/**
 * We need a way of restoring the device info when the app is reloaded
 * (via window.reload or similar), since the query string won't survive
 * the initial app load. Here we save and restore the device info from
 * localstorage, in order for tracking to keep working properly even if
 * the app hasn't received the device info from the TV package.
 */
if (parsedDeviceInfo) {
  // Always save the up-to-date device info if present
  localStorage.setItem(
    DEVICE_INFO_STORAGE_KEY,
    JSON.stringify(parsedDeviceInfo)
  );
} else {
  // Try loading device info from localStorage if not present in URL
  try {
    const storedDeviceInfo = localStorage.getItem(DEVICE_INFO_STORAGE_KEY);
    if (storedDeviceInfo) {
      const parsedStoredDeviceInfo = JSON.parse(
        storedDeviceInfo
      ) as DeviceInfo | null;

      if (parsedStoredDeviceInfo) {
        parsedDeviceInfo = parsedStoredDeviceInfo;
      }
    }
  } catch {
    // Swallow error silently
  }
}

export const getSamsungDeviceModelType = (): SamsungTVModelGroups | null =>
  deviceInfoContainer.deviceInfo?.model
    ? extractSamsungTVModelGroup(deviceInfoContainer.deviceInfo.model)
    : null;

const deviceInfoContainer: {
  hostedAppVersion: string;
  deviceInfo: DeviceInfo | null;
} = {
  hostedAppVersion: getEnvVariable('REACT_APP_HEROKU_RELEASE_VERSION', ''),
  deviceInfo: parsedDeviceInfo
};

const getPlatformOsVersion = async () => {
  switch (Platform.OS) {
    case 'lg':
      return await getExactWebosVersion();
    case 'samsung':
      return getSamsungVersion();
    case 'philips':
      return getPhilipsVersion();
  }
};

const useOsVersion = () => {
  const [osVersion, setOsVersion] = useState<string>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const detectedOsVersion = await getPlatformOsVersion();
      setOsVersion(detectedOsVersion?.toString());
      setLoading(false);
    })();
  }, []);

  if (loading) return { loading: true };
  return { loading: false, osVersion };
};

const versionSeparator = '__';

export const useDevice = () => {
  const { loading, osVersion } = useOsVersion();

  if (loading) return { device: null, loading: true } as const;

  const device: DeviceInterface = {
    id: deviceInfoContainer.deviceInfo?.deviceUniqueId ?? 'unknown',
    type: DeviceType.SmartTv,
    version: [
      deviceInfoContainer.deviceInfo?.version,
      osVersion,
      deviceInfoContainer.hostedAppVersion
    ]
      .filter(exists)
      .join(versionSeparator),
    model: deviceInfoContainer.deviceInfo?.model ?? 'unknown',
    manufacturer: Platform.OS ?? 'unknown',
    token: 'unknown'
  };

  return { device, loading: false } as const;
};

export const getOsVersion = (device: DeviceInterface) => {
  return device.version.split(versionSeparator)[1];
};

export const getAppVersion = (): string =>
  deviceInfoContainer.deviceInfo?.version ?? '';

export const parseAppVersion = (
  appVersion: string
): ParsedAppVersion | null => {
  const [rawMajor, rawMinor, rawPatch] = appVersion.split('.');

  const major = parseInt(rawMajor);
  const minor = parseInt(rawMinor);
  const patch = parseInt(rawPatch);

  if (isNaN(major) || isNaN(minor) || isNaN(patch)) {
    return null;
  }

  return [major, minor, patch];
};

export const isTizen2 = () => {
  return navigator.userAgent.includes('Tizen 2');
};

const LOCALE_KEY = 'SHAPESHIFTER_SAVED_LOCALE';

export const saveLocale = (locale: Locale) => {
  try {
    localStorage.setItem(LOCALE_KEY, locale);
  } catch (e) {
    // Do nothing
  }
};

export const getLocale = (): Locale | null => {
  try {
    const savedLocale = localStorage.getItem(LOCALE_KEY) as Locale | null;
    if (savedLocale) return savedLocale;
  } catch (e) {
    // Do nothing
  }

  if (navigator?.language.includes(Locale.sv)) return Locale.sv;
  if (navigator?.language.includes(Locale.no)) return Locale.no;
  if (navigator?.language.includes(Locale.da)) return Locale.da;
  if (navigator?.language.includes(Locale.fi)) return Locale.fi;

  return null;
};

export const isLowResolution = () => {
  return window.innerHeight < 1080;
};
