import {
  Candidate,
  Direction,
  ElWithRect,
  FocusGroup,
  RectPosition
} from '../types';
import { getCaret } from '../utils';
import { distanceBetweenTwoPoints } from './distanceBetweenPoints';
import { distanceToLineSegment } from './distanceToLineSegment';

const directionPoints: Record<Direction, RectPosition> = {
  down: 'bottomCenter',
  left: 'leftCenter',
  right: 'rightCenter',
  up: 'topCenter'
};

const directionLine: Record<Direction, [RectPosition, RectPosition]> = {
  down: ['topLeft', 'topRight'],
  left: ['topRight', 'bottomRight'],
  right: ['topLeft', 'bottomLeft'],
  up: ['bottomLeft', 'bottomRight']
};

export const getClosestFocusable = ({
  direction,
  elements,
  fromElement,
  focusGroups,
  averageInputCharacterWidth
}: {
  direction: Direction | 'any';
  elements: ElWithRect[];
  fromElement: React.MutableRefObject<ElWithRect | undefined>;
  focusGroups: Record<string, FocusGroup | undefined>;
  averageInputCharacterWidth: number;
}) => {
  const candidates: Candidate[] = [];
  const caret = getCaret({
    element: fromElement.current,
    averageInputCharacterWidth
  });

  for (const b of elements) {
    const currentFocus = fromElement?.current;
    if (!currentFocus) continue;

    const visible = isVisible(b.element.node);
    if (!visible) continue;

    const { element: currentElement } = currentFocus;
    const groupChildren =
      (b.element.isFocusGroup &&
        focusGroups[b.element.groupId]?.focusableChildren) ||
      [];

    const currentShape = caret ?? currentFocus.rect;

    // skip if self
    if (currentElement === b.element) continue;

    const inSameGroup =
      currentElement.parentGroupId === b.element.parentGroupId;

    // skip if element is outside the group and the group is blocking current movement
    if (!inSameGroup) {
      if (!currentElement.parentGroupId) continue;
      const currentGroup = focusGroups?.[currentElement.parentGroupId];
      if (currentGroup?.blockMovingDown && direction === 'down') continue;
      if (currentGroup?.blockMovingUp && direction === 'up') continue;
      if (currentGroup?.blockMovingRight && direction === 'right') continue;
      if (currentGroup?.blockMovingLeft && direction === 'left') continue;
    }

    // skip current group if focused element is within
    if (
      b.element.isFocusGroup &&
      currentElement.parentGroupId === b.element.groupId
    ) {
      continue;
    }

    // skip groups with no direct children
    if (
      b.element.isFocusGroup &&
      groupChildren.filter((el) => !el.isFocusGroup).length === 0
    ) {
      continue;
    }

    // skip if point is in wrong direction
    if (direction === 'up') {
      if (b.rect.bottomCenter.y >= currentShape.bottomCenter.y) continue;
      if (b.rect.topCenter.y >= currentShape.topCenter.y) continue;
    }
    if (direction === 'down') {
      if (b.rect.bottomCenter.y <= currentShape.bottomCenter.y) continue;
      if (b.rect.topCenter.y <= currentShape.topCenter.y) continue;
    }
    if (direction === 'left') {
      if (b.rect.leftCenter.x >= currentShape.leftCenter.x) continue;
    }
    if (direction === 'right') {
      if (b.rect.leftCenter.x <= currentShape.leftCenter.x) continue;
    }

    const isOnSameLayer = currentElement.layer === b.element.layer;

    const isStraightInDesiredDirection = (() => {
      if (direction === 'down' || direction === 'up') {
        if (
          b.rect.leftCenter.x <= currentShape.center.x &&
          b.rect.rightCenter.x >= currentShape.center.x
        ) {
          return true;
        }
      }
      if (direction === 'right' || direction === 'left') {
        if (
          b.rect.topCenter.y <= currentShape.center.y &&
          b.rect.bottomCenter.y >= currentShape.center.y
        ) {
          return true;
        }
      }
      return false;
    })();

    const canScrollGroupInDirection = (groupId: string) => {
      const groupNode = focusGroups[groupId]?.node;
      if (!groupNode) return false;
      const element = groupNode as HTMLDivElement;
      const scrolledToTop = element.scrollTop === 0;
      const scrolledToLeft = element.scrollLeft === 0;
      const scrolledToRight =
        element.scrollLeft + element.clientWidth >= element.scrollWidth;
      const scrolledToBottom =
        element.scrollTop + element.clientHeight >= element.scrollHeight;
      if (direction === 'up' && !scrolledToTop) return true;
      if (direction === 'down' && !scrolledToBottom) return true;
      if (direction === 'left' && !scrolledToLeft) return true;
      if (direction === 'right' && !scrolledToRight) return true;
      return false;
    };

    // Element is in the currently scrollable group in the direction of the movement.
    const insideCurrentScrollableGroupInDirection = (() => {
      const groupThatCanBeScrolledInDirection = currentElement.groups.find(
        canScrollGroupInDirection
      );
      if (!groupThatCanBeScrolledInDirection) return false;
      const elementIsInScrollableGroup = b.element.groups.includes(
        groupThatCanBeScrolledInDirection
      );
      return elementIsInScrollableGroup;
    })();

    // See README for things to consider when changing the score.
    // Higher score means better candidate to be focused.
    // 10000 is picked to be a high enough to outnumber any arbitrary distance
    let score = 0;
    if (isOnSameLayer) score += 10000;
    if (isStraightInDesiredDirection) score += 10000;
    if (insideCurrentScrollableGroupInDirection) score += 10000;

    const distance = (() => {
      if (direction === 'any') {
        return distanceBetweenTwoPoints(
          { x: currentShape.center.x, y: currentShape.center.y },
          { x: b.rect.center.x, y: b.rect.center.y }
        );
      }
      const pointPos = directionPoints[direction];
      const line = directionLine[direction];
      const lineSegmentStart = b.rect[line[0]];
      const lineSegmentEnd = b.rect[line[1]];
      const point = currentShape[pointPos];
      return distanceToLineSegment({
        point,
        lineSegmentStart,
        lineSegmentEnd
      });
    })();

    // Weighted distance is an artificially distance considering the real distance and
    // the "focus score". Lowest weighted distance means next element to be focused.
    const weightedDistance = distance - score;

    candidates.push({
      ...b,
      distance,
      weightedDistance
    });
  }

  const getClosesElement = (candidates: Candidate[]) => {
    let closestElement: Candidate | undefined;
    for (const el of candidates) {
      if (
        !closestElement ||
        el.weightedDistance < closestElement.weightedDistance
      ) {
        closestElement = el;
      }
    }
    return closestElement;
  };

  let closestElement = getClosesElement(candidates);

  // If the closest element is a group, find the closest element within that group
  // Reason for not picking the closest element directly is that we want the entire
  // focus group to be able to "catch focus".
  const closest = closestElement?.element;
  if (closest?.isFocusGroup) {
    const candidatesInClosestGroup = candidates
      .filter((c) => c !== closestElement)
      .filter((c) => c.element.parentGroupId === closest.groupId);

    closestElement = getClosesElement(candidatesInClosestGroup);
  }

  return { closestElement, candidates };
};

const isVisible = (node: ChildNode) => {
  const style = window.getComputedStyle(node as Element);
  if (style.visibility === 'hidden') return false;
  if (style.display === 'none') return false;
  if (style.opacity === '0') return false;
  return true;
};
