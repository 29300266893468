import { themeColor } from '@sfstudios/shapeshifter';
import { css } from 'styled-components';
import { isLowResolution } from './deviceInfo';
import { isLowEnd, Platform } from './platform';

export const glassEffect = () => {
  if (!Platform.isModern()) {
    return css`
      background: ${themeColor('ui500', 0.85)};
    `;
  }

  return css`
    background: ${themeColor('ui500', 0.85)};
    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background: ${themeColor('white', 0.05)};
      -webkit-backdrop-filter: blur(24px);
      backdrop-filter: blur(24px);
    }
  `;
};

/**
 * Creates a gap between elements
 * @param gapSize The size of the gap in pixels
 * @param direction The direction of the gap
 */
export const gap = (gapSize: number, direction: 'row' | 'column') => css`
  ${direction === 'row'
    ? `& > *:not(:last-child) {
    margin-right: ${gapSize}px;
  }`
    : `& > *:not(:last-child) { margin-bottom: ${gapSize}px; }`}
`;

export const focusBorderWidth = 4;

/**
 * box-sizing makes border not change size (required for focus calulations to work.
 * outline would be possible to use instead of border but
 */
export const focusStyles = css`
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: ${focusBorderWidth}px solid transparent;
  box-sizing: border-box;
  background-clip: padding-box;
  :focus {
    border: ${focusBorderWidth}px solid white;
  }
`;

/** all:unset is not supported on 2016 and breaks other styling on at least lg 2016 */
export const allUnset = css`
  ${isLowEnd ? '' : 'all: unset;'}
  background-color: transparent;
  border: none;
`;

// The css classes cannot be combined (comma separated) since
// the :focus-within selector is unsupported on older platforms
// and then no css at all will be applied.
// Using css function input parameter is used there to get prettier formatting correct
// @ts-ignor: any can be replaced with `typeof css<T>` when react-scripts is updated to >= 5.
// (react-scripts<5 doesn't handle high enough typescript version to compile `typeof css<T>`)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function focusWithin(styles: any) {
  return css`
    &.focus-within {
      ${styles}
    }
    :focus-within {
      ${styles}
    }
  `;
}

/** 100vh is not always "full screen" because zoom is used on body in index.css  */
export const vh100 = isLowResolution() ? '155vh' : '100vh';
export const vw100 = isLowResolution() ? '155vw' : '100vw';
