import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef
} from 'react';

interface OOB {
  left: (...args: any[]) => void;
  right: (...args: any[]) => void;
  down: (...args: any[]) => void;
  up: (...args: any[]) => void;
}

const noop = () => {};
const OutOfBoundsContext = createContext<React.MutableRefObject<OOB>>({
  current: {
    left: noop,
    right: noop,
    down: noop,
    up: noop
  }
});

interface Props extends Partial<OOB> {
  children: ReactNode;
  propagate?: boolean;
}

export const OutOfBoundsProvider = ({
  left,
  right,
  down,
  up,
  propagate,
  children
}: Props) => {
  const parentOutOfBounds = useContext(OutOfBoundsContext);

  const currentValue = propagate
    ? {
        left: left ?? (() => parentOutOfBounds.current.left()),
        right: right ?? (() => parentOutOfBounds.current.right()),
        down: down ?? (() => parentOutOfBounds.current.down()),
        up: up ?? (() => parentOutOfBounds.current.up())
      }
    : {
        left: left ?? noop,
        right: right ?? noop,
        down: down ?? noop,
        up: up ?? noop
      };

  const value = useRef<OOB>(currentValue);

  useEffect(() => {
    value.current = currentValue;
  });

  return (
    <OutOfBoundsContext.Provider value={value}>
      {children}
    </OutOfBoundsContext.Provider>
  );
};

export const useOutOfBoundsRef = (): React.MutableRefObject<OOB> => {
  return useContext(OutOfBoundsContext);
};
