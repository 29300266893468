import * as Sentry from '@sentry/browser';
import { ApolloClient, DeviceInterface } from '@sfstudios/shapeshifter';
import { useEffect, useRef, useState } from 'react';
import { getClient } from '../utils/apolloClient';
import { useDevice } from '../utils/deviceInfo';
import { getTrackers } from '../utils/trackers';

const BOOT_FAILED_TIMEOUT = 15000;

let APOLLO_CLIENT: ApolloClient | null = null;
let TRACKERS: ReturnType<typeof getTrackers> | null = null;

type SetupDeviceAndClient =
  | {
      client: ApolloClient;
      device: DeviceInterface;
      trackers: ReturnType<typeof getTrackers>;
      ready: true;
    }
  | {
      client: null;
      device: null;
      trackers: null;
      ready: false;
    };

export const useSetupDeviceAndClient = (): SetupDeviceAndClient => {
  const { loading: deviceLoading, device } = useDevice();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (deviceLoading || !device) return;
    if (APOLLO_CLIENT !== null) return;

    (async () => {
      try {
        APOLLO_CLIENT = await getClient(device);
        TRACKERS = getTrackers(APOLLO_CLIENT);

        setReady(true);
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
      }
    })();
  }, [device, deviceLoading]);

  // Allow some time to pass before we report boot failure
  const timeout = useRef<number | undefined>(undefined);
  useEffect(() => {
    timeout.current = setTimeout(() => {
      const msg = `Boot failed: Failed to setup device, trackers and client.`;
      if (process.env.NODE_ENV === 'development') console.error(msg);

      Sentry.captureMessage(msg);
    }, BOOT_FAILED_TIMEOUT);

    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  useEffect(() => {
    if (
      !ready ||
      APOLLO_CLIENT === null ||
      TRACKERS === null ||
      device === null
    ) {
      return;
    }

    clearTimeout(timeout.current);
  }, [device, ready]);

  if (
    !ready ||
    APOLLO_CLIENT === null ||
    TRACKERS === null ||
    device === null
  ) {
    return {
      client: null,
      device: null,
      trackers: null,
      ready: false
    };
  }

  return {
    client: APOLLO_CLIENT,
    trackers: TRACKERS,
    device,
    ready
  };
};
