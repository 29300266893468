// this comments prevents imports above to be reordered
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
// this comments prevents imports above to be reordered
import 'allsettled-polyfill';
import 'intersection-observer';
import 'scrollyfills';
import 'focus-within-polyfill';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
// this comments prevents imports above to be reordered
import ReactDOM from 'react-dom';
import './utils/initDarkThemeBrand';
// this comments prevents imports above to be reordered
import { enableES5 } from 'immer';
import './index.css';
import Root from './Root';
import * as serviceWorker from './serviceWorker';

// prevent crash on older samsungs <= 2017 etc
enableES5();

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
