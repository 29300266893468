import { useL10n, useMenu } from '@sfstudios/shapeshifter';
import { useHistory } from 'react-router-dom';
import { FocusGroup } from '../../../focus-engine/FocusGroup';
import { whiteSpace } from '../../../theme';
import { NavigationItem, resolvePath } from '../../../utils/navigation';
import { Button } from './Button';
import * as MoreStyled from './More.styled';

const MoreButton = ({ item }: { item: NavigationItem }) => {
  const history = useHistory();
  const { t } = useL10n();
  const path = resolvePath({ item });
  return (
    <Button
      key={item.id}
      onClick={() => {
        history.push(path);
      }}
      icon={item.icon}
    >
      {t(item.titleKey)}
    </Button>
  );
};

const More = () => {
  const { t } = useL10n();
  const { items } = useMenu({ type: 'more' });
  return (
    <FocusGroup
      style={{ marginTop: whiteSpace['24'] }}
      id={'more-page'}
      blockMovingDown
      blockMovingUp
    >
      <MoreStyled.Heading>{t('navigation.more')}</MoreStyled.Heading>
      <MoreStyled.SubHeading>{t('navigation.settings')}</MoreStyled.SubHeading>
      <MoreStyled.TableWrapper>
        <MoreStyled.ColumnWrapper>
          {items.slice(0, 4).map((item, idx) => (
            <MoreButton key={idx} item={item} />
          ))}
        </MoreStyled.ColumnWrapper>
        <MoreStyled.ColumnWrapper>
          {items.slice(4).map((item, idx) => (
            <MoreButton key={idx} item={item} />
          ))}
        </MoreStyled.ColumnWrapper>
      </MoreStyled.TableWrapper>
    </FocusGroup>
  );
};

export default More;
