import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useRef
} from 'react';

export const FocusContext = createContext(true);

export const FocusContextProvider = ({
  children,
  value
}: {
  children: ReactNode;
  value: boolean;
}) => {
  const parentFocused = useContext(FocusContext);
  return (
    <FocusContext.Provider value={value && parentFocused}>
      {children}
    </FocusContext.Provider>
  );
};

export type FocusableProps<T> = T & {
  focused: boolean;
  as: string | ((x: T) => ReactNode) | ReactNode;
  innerRef?: React.MutableRefObject<any>;
  children?: ReactNode;
  onFocused?: (target: HTMLElement) => void;
};

type AfterScrollProps<T> = FocusableProps<T> & {
  ready: boolean;
  row: number;
  setScrollPos: (y: number, i: number) => void;
};
const halfScreen = window.innerHeight / 2;

const getOffset = (element: any): number => {
  if (!element) return 0;
  return element.offsetTop + getOffset(element.offsetParent);
};

export const FocusableAfterScroll = <T extends object>({
  focused,
  ready,
  setScrollPos,
  row,
  ...rest
}: AfterScrollProps<T>) => {
  const ref = useRef<HTMLDivElement>(null!);
  const parentFocused = useContext(FocusContext);
  focused = focused && parentFocused;

  useEffect(() => {
    if (!focused) return;

    requestAnimationFrame(() => {
      if (!ref.current) return;
      const offset = getOffset(ref.current);
      const height = ref.current.getBoundingClientRect().height;

      const newScrollPos = offset + height / 2 - halfScreen;

      // Things are going wrong. We did not find anything to scroll to Abort!
      if (offset + height === 0) {
        if (process.env.NODE_ENV !== 'production') {
          console.warn(
            'Failed to scroll to object as its height + offset was = 0. This Almost always is that it does not exist.'
          );
        }
        return;
      }

      setScrollPos(Math.max(0, newScrollPos), row);
    });
  }, [focused, row, setScrollPos]);

  return (
    <Focusable {...(rest as any)} focused={ready && focused} innerRef={ref} />
  );
};

const NOOP = () => {};

const InnerFocusable = <T extends object>({
  focused,
  as = 'a',
  children,
  innerRef,
  onFocused = NOOP,
  ...rest
}: FocusableProps<T>) => {
  const prevFocusRef = useRef<boolean | null>(null);
  const hookRef = useRef<any>(null!);
  const ref = innerRef || hookRef;

  useEffect(() => {
    if (focused) {
      if (!ref.current) {
        console.error(
          'Focusable has no ref, dont forget to forward refs for custom components'
        );
        return;
      }
      if (!ref.current.contains(document.activeElement)) {
        ref.current.focus();
      }
      if (prevFocusRef.current !== focused) {
        onFocused(ref.current);
      }
    }
    prevFocusRef.current = focused;
  }, [focused, ref, as, prevFocusRef, onFocused]);

  const Input = as;
  const shouldClone = React.isValidElement(Input);

  type InnerProps = T & {
    focused: any;
    tabIndex: number;
    ref: React.MutableRefObject<any>;
  };
  const element: ReactElement<InnerProps> | null = shouldClone
    ? (Input as any)
    : null;

  const Comp: React.FC<InnerProps> = shouldClone ? null : (Input as any);

  const props: any = {
    tabIndex: 0,
    ref,
    focused: focused ? 1 : 0,
    ...rest
  };

  if (children) {
    props.children = children;
  }

  return (
    <FocusContextProvider value={focused}>
      {element ? React.cloneElement(element, props) : <Comp {...props} />}
    </FocusContextProvider>
  );
};

export const Focusable = <T extends object>({
  focused,
  ...rest
}: FocusableProps<
  T & { onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void }
>) => {
  const parentFocused = useContext(FocusContext);
  focused = focused && parentFocused;

  return <InnerFocusable {...(rest as FocusableProps<T>)} focused={focused} />;
};
