import React, { createContext, useContext, useEffect, useState } from 'react';
import { Platform } from '../utils/platform';

export const MouseContext = createContext(false);

export const useIsCursorVisible = () => useContext(MouseContext);

export const MouseContextProvider: React.FC = ({ children }) => {
  /**
   * This keeps track of if there is a cursor or not (currently only used in LG)
   */
  const [isCursorVisible, setisCursorVisible] = useState(false);

  function lgCursorVisibilityChange(event: any) {
    const visibility = event.detail.visibility;
    if (visibility) {
      setisCursorVisible(true);
    } else {
      setisCursorVisible(false);
    }
  }

  function cursorMoved() {
    setisCursorVisible(true);
  }

  useEffect(() => {
    document.addEventListener(
      'cursorStateChange',
      lgCursorVisibilityChange,
      false
    );
    document.addEventListener('mousemove', cursorMoved);

    return () => {
      document.removeEventListener(
        'cursorStateChange',
        lgCursorVisibilityChange,
        false
      );
      document.removeEventListener('mousemove', cursorMoved);
    };
  }, []);

  return (
    <MouseContext.Provider
      value={Platform.supportsMouse() ? isCursorVisible : false}
    >
      {children}
    </MouseContext.Provider>
  );
};
