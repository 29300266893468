import {
  ApolloClient,
  EventTrackerListener,
  TrackerCategory
} from '@sfstudios/shapeshifter';
import { ClientMetrics } from '@sfstudios/shapeshifter';
import { apolloLog } from '../apolloLog';
import { EVENTS } from './events';

const tracker = (client: ApolloClient): EventTrackerListener<EVENTS> => {
  const cMetrics = new ClientMetrics({
    logFunc: (logs: string[]) => apolloLog(client, logs)
  });

  const tracker: EventTrackerListener<EVENTS> = {
    category: TrackerCategory.Required,
    id: 'splunkTracker',
    onLoad: () => {},
    listener: (event) => {
      const { payload } = event;
      switch (event.type) {
        case '@shapeshifter-core/BOOT':
          break;
        case EVENTS.CLICK_NEXT_EPISODE_BUTTON:
        case EVENTS.CLICK_SKIP_INTRO_BUTTON:
        case EVENTS.SHOW_NEXT_EPISODE_BUTTON:
        case EVENTS.SHOW_SKIP_INTRO_BUTTON:
        case EVENTS.CHECKOUT_COMPLETED:
        case 'listItemClick':
        case EVENTS.BUY_BUTTON_CLICK:
        case 'playButtonClick':
        case EVENTS.LOG_IN_STARTED:
        case 'loggedIn':
        case EVENTS.NAVIGATION_MENU_CLICK:
        case EVENTS.COVER_CLICK_EVENT:
        case 'notifyMe':
        case 'addFavorite':
        case EVENTS.TAKEOVER_INFO_CLICK:
        case EVENTS.QR_CODE_URL_ERROR:
        case EVENTS.QR_CODE_URL_SUCCESS:
        case 'searchFilterChanged':
        case 'searchReset':
        case 'profiling/renderCompleted':
        case 'profiling/renderInterfered':
        case 'search/usedShortcut':
        case 'search/saveSearch':
        case 'search/removeSearch':
          cMetrics.logFunc({
            args: {
              evt: event.type,
              ...payload
            },
            level: 'info'
          });
          break;
        default:
          break;
      }
    }
  };
  return tracker;
};

export default tracker;
